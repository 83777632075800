const ACTIONS = Object.freeze({
  LOADING_PROJECTS: "LOADING_PROJECTS",
  PROJECTS_LOADED: "PROJECTS_LOADED",

  LOADING_CERTIFICATES: "LOADING_CERTIFICATES",
  CERTIFICATES_LOADED: "CERTIFICATES_LOADED",

  LOADING_WORKEXPERIENCES: "LOADING_WORKEXPERIENCES",
  WORKEXPERIENCES_LOADED: "WORKEXPERIENCES_LOADED",

  LOADING_SKILLS: "LOADING_SKILLS",
  SKILLS_LOADED: "SKILLS_LOADED",

  ADD_DUMMY_SKILL: "ADD_DUMMY_SKILL",

  LOADING_EDUCATIONS: "LOADING_EDUCATIONS",
  EDUCATIONS_LOADED: "EDUCATIONS_LOADED",

  LOADING_USER: "LOADING_USER",
  USER_LOADED: "USER_LOADED",

  USER_FROM_GOOGLE: "USER_FROM_GOOGLE",

  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",

  SHOW_SNACKBAR: "SHOW_SNACKBAR",

  ADD_DUMMY_CERTIFICATE: "ADD_DUMMY_CERTIFICATE",

  ADD_DUMMY_EDUCATION: "ADD_DUMMY_EDUCATION",

  ADD_DUMMY_EXPERIENCE: "ADD_DUMMY_EXPERIENCE",

  ADD_DUMMY_PROJECT: "ADD_DUMMY_PROJECT",

  USER_NOT_FOUND: "USER_NOT_FOUND",
});

export { ACTIONS };
