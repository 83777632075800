const ErrorPage = () => {
  return (
    <div>
      <div>Something went wrong</div>
      <div>Possible reasons are...</div>
      <ul>
        <li>This url does not exists</li>
        <li>This user does not exists</li>
      </ul>
    </div>
  );
};

export default ErrorPage;
